<template>
   <div>
     <v-banner
        single-line
        height="500"
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/num-banner-bg-IndustrialManufacturing.png"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="px-6 d-flex max-width-1200 align-center">
          <div class="flex-grow-1">
            <v-card color="transparent" flat >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="white--text font-size-banner-title font-weight-bold px-0" >
                    智慧工厂
                  </v-card-title>
                  <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px">
                    数字孪生助力工厂数字化转型
                  </v-card-title>
                </div>
              </div>
            </v-card>
          </div>
          <div class="flex-grow-1">
            <v-img src="https://h5.ophyer.cn/official_website/banner/num-banner-img-IndustrialManufacturing.png" contain style="height: 400px"></v-img>
          </div>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 功能 -->
    <v-card class="warp warp2" flat style="padding-bottom: 40px">
      <div class="public-title">
        <p>FUNCTION </p>
        <div>
          <span>功能</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs mt-8">
        <v-tab v-for="(v, k) in list" :key="k">
          <b>{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in list" :key="k" transition="fade-transition">
          <div class="box">
            <transition name="slide-fade" appear >
              <div class="left" v-show="tab == k"><img :src="v.img" /></div>
            </transition>
            <transition name="slide-fade-reverse" appear >
              <div class="right" v-show="tab == k">
                <v-card-text class="title" v-text="v.h2" />
                <v-card-text class="tip" v-text="v.content" v-html="v.content" />
              </div>
            </transition>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- 方案优势 -->
    <v-card class="justify-center program_advantages" flat color="#FBFBFB">
      <div class="public-title">
        <p>PROGRAM ADVANTAGES</p>
        <div>
          <span>方案优势</span>
        </div>
      </div>
      <div class="model_list2">
        <v-row no-gutters justify='space-between' class="px_102">
          <v-col class="px-4 mb-10"  v-for="(v, k) in modelList2" :key="k" cols="4" sm="4" >
            <div class="model_item">
              <img class="item_img" :src='v.icon' />
              <div class="item_info">
                <v-card-title class="justify-center reset-title">{{ v.title }}</v-card-title>
                <v-card-text class="reset-text">{{ v.text }}</v-card-text>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    
    <!-- 展示终端 -->
    <v-card class="justify-center display_terminal" flat color="#FBFBFB">
      <div class="public-title">
        <p>3D CONTENT PRODUCTION</p>
        <div>
          <span>展示终端</span>
        </div>
      </div>
      <div class="modelList3">
        <div class="model_item" v-for="(v, k) in modelList3" :key="k">
          <img class="item_img" :src='v.img' />
          <div class="item_info">
            <div>
              <div class="info_title">{{ v.title }}</div>
              <div class="info_text">{{ v.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
   </div>
</template>

<script>
import qs from 'qs'
export default {
  name: "IndustrialManufacturing",
  data(){
    return{
      model: 0,
      tab:null,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      list:[
        {title:"空间漫游",h2:'空间漫游',img:'https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-1.png', content:'用户可不受限制，自由在3D模式浏览'},
        {title:"资源快速定位",h2:'资源快速定位',img:'https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-2.png',
          content:'三维模型通过搜索可以快速对人员、物体、监控探头定位，并弹出对应信息及附近可用资源信息'},
        {title:"智能巡更",h2:'智能巡更',img:'https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-3.png',
          content:'在三维场景中设定自定义巡更路线，巡更过程中可以自动弹出巡更路线附近监控图像，方便巡更人员发现并迅速定位异常'},
        {title:"视频监控",h2:'视频监控',img:'https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-4.png',
          content:'GIS实现对单位中各种监控设备的控制，用户可在监控系统中实时查看控制、查看监控设备'},
      ],
      modelList2: [
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-IndustrialManufacturing-1.png",
          title: "高效管理",
          text: "资源快速定位，参数实时监控，大大提升管理效率"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-IndustrialManufacturing-2.png",
          title: "挖掘生产潜力",
          text: "工厂的运行状态可被实时监控，各环节的不足可被即可定位并优化"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-IndustrialManufacturing-3.png",
          title: "降低生产风险",
          text: "智能巡更配合视频监控，快速发现风险并定位问题关键点"
        }
      ],
      modelList3: [
        {
          img: "https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-5.png",
          title: "手机电脑端",
          text: "可以在线上转发传播"
        },
        {
          img: "https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-6.png",
          title: "飞天立体交互墙",
          text: "在小间距LED的基础上增加交互装置 打造了替代投影教学方案的全新沉浸式教学系统"
        },
      ],
      solutions: [
        { title: 'VR播控系统', src: 'https://h5.ophyer.cn/official_website/other/learnimg31.jpg', content:'用于教学/实验、拓展体验，方案灵活，<br/>易落地',anli:'bokongxitong'},
        { title: '全景运营创业培训', src: 'https://h5.ophyer.cn/official_website/other/learnimg32.jpg', content:'设备使用教学+全景制作工具培训，享受<br/>平台派单，创业如此简单',anli:'chuangyepeixun'},
      ],
      advantage:[
        { title: '国家政策扶持', src: 'https://h5.ophyer.cn/official_website/other/learnYS1.png', content:'匹配教育部对虚拟现实专业的建设要求'},
        { title: '大平台技术支撑', src: 'https://h5.ophyer.cn/official_website/other/learnYS2.png', content:'虚拟现实应用技术引领者，打造VR<br/>虚拟现实教学平台'},
        { title: '教学资源丰富', src: 'https://h5.ophyer.cn/official_website/other/learnYS3.png', content:'对接主流教育行业，拥有各专业丰富的<br/>教学资源'},
        { title: '完善的课程体系', src: 'https://h5.ophyer.cn/official_website/other/learnYS4.png', content:'完善的课程体系，打造一站式智慧<br/>教育平台'},
        { title: '满足多场景教学', src: 'https://h5.ophyer.cn/official_website/other/learnYS5.png', content:'满足教、学、练、测、评的需求，全场景<br/>教学，提升学习效率'},
        { title: '专业拓展', src: 'https://h5.ophyer.cn/official_website/other/learnYS6.png', content:'依托虚拟现实技术，支持各专业虚拟仿真<br/>训练室的建设'},
      ]
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang='scss'>
::v-deep .v-card__subtitle, .v-card__text ,.text-subtitle-1{
  line-height: 26px !important;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner_img{
    height: 100%;
    object-fit: contain;
  }
}
::v-deep.banner_title {
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF !important;
  // padding-top: 10%;
}
.scence_text{
  color: #FFFFFF !important;
}
.banner_span {
  font-family: Source Han Sans CN;
  color: #2672FF;
  font-size: 20px;
}
::v-deep.solutions_row{
  width: 960px;
  margin: 0 auto;
}
.warp {
  margin: 83px auto 0;
  text-align: center;
}

.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 20px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
.program_advantages{
  width: 100%;
  height: 380px;
  padding-top: 60px;
  text-align: center;
  .public-title{
    margin-top: 0;
  }
  .model_list2 {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .model_item{
      width: 300px;
      height: 210px;
      border-radius: 16px;
      padding: 15px 12px;
      .item_img{
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
      .item_info{
        margin-top: 10px;
      }
    }
  }
}
.display_terminal{
  width: 100%;
  height: 420px;
  padding-top: 60px;
  text-align: center;
  .public-title{
    height: 75px;
    margin-top: 0;
  }
  .modelList3{
    width: 1200px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    .model_item{
      padding: 15px 20px;
      position: relative;
      border-radius: 16px;
      width: 340px;
      height: 240px;
      transition: all 0.3s ease-in;
      .item_img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
      .item_info{
        position: absolute;
        top: 15px;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 210px;
        background: #000000;
        border-radius: 16px;
        opacity: 0.5;
        .info_title{
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .info_text{
          margin-top: 10px;
          padding: 0 15px;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 16px;
        }
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.img_title {
    // width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 30px;
      border-radius: 10px;
      width:400px ;
      height: 260px;
      object-fit: cover;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
}
::v-deep .look_btn{
  width: 120px;
  height: 40px !important;
  background: #0568FD !important;
  border-radius: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 13px;
}
::v-deep .v-carousel__controls{
  display: none !important;
}
.advantage_card{
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
}
</style>